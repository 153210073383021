<template>
  <div class="handle-detail" :class="{ 'no-btn': ['2'].includes(orderStatus) }">
    <div class="scroll-box">
      <workOrderForm
        ref="work_order_form"
        mode="view"
        :existForm="existForm"
        :orderId="orderId"
      />
      <disposeForm
        v-if="['1', '2', '3'].includes(orderStatus)"
        ref="dispose_form"
        :mode="['2'].includes(orderStatus) ? 'view' : 'edit'"
        :detail="detail"
        @submit="submitOnHandle"
      />
    </div>
    <div class="form-btns" v-if="!!orderStatus">
      <!-- <van-button
        v-if="['0'].includes(orderStatus)"
        size="small"
        round
        type="info"
        native-type="button"
        :loading="takeLoading"
        style="width: 80%"
        @click="takeOnClick"
      >
        接单
      </van-button>
      <van-button
        v-if="['1', '3'].includes(orderStatus)"
        size="small"
        round
        type="info"
        native-type="button"
        :loading="submitLoading"
        style="width: 80%"
        @click="submitOnClick"
      >
        提交
      </van-button> -->
    </div>
  </div>
</template>

<script>
import { updateOrderWorkStatusUrl } from "./api.js";
import workOrderForm from "../component/workOrderForm.vue";
import disposeForm from "../component/disposeForm.vue";
import { Button } from "vant";
import { mapState } from "vuex";

export default {
  name: "handleDetail",
  components: {
    [Button.name]: Button,
    workOrderForm,
    disposeForm,
  },
  props: {},
  model: {},
  data() {
    return {
      takeLoading: false,
      submitLoading: false,
      orderStatus: "",
      detail: null,
      orderId: "",
    };
  },
  computed: {
    ...mapState(["userId"]),
  },
  watch: {},
  created() {
    this.orderId = this.$route.query.orderId;
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    existForm(detail, form, textForm) {
      this.orderStatus = detail.orderStatus;
      this.detail = detail;
    },
    takeOnClick() {
      this.takeLoading = true;
      const params = {
        disposeUserId: this.userId,
        orderId: this.$route.query.orderId,
        orderStatus: "1",
      };
      this.$axios
        .post(updateOrderWorkStatusUrl, params)
        .then((res) => {
          if (res && res.code === 200) {
            this.$toast(res.msg);
            this.orderStatus = "1";
          }
        })
        .finally(() => {
          this.takeLoading = true;
        });
    },
    submitOnClick() {
      this.$refs.dispose_form.submit();
    },
    submitOnHandle(values, form, textForm) {
      this.submitLoading = true;
      form.disposeUserId = this.userId;
      form.orderId = this.$route.query.orderId;
      form.orderStatus = "2";
      Reflect.deleteProperty(form, "photos");
      this.$axios
        .post(updateOrderWorkStatusUrl, form)
        .then((res) => {
          if (res && res.code === 200) {
            this.$toast(res.msg);
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.handle-detail {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding-bottom: calc(30px + constant(safe-area-inset-bottom));
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
  background-color: #f8f8f8;
  &.no-btn {
    .scroll-box {
      max-height: 100%;
    }
    .form-btns {
      display: none;
    }
  }
}
.scroll-box {
  box-sizing: border-box;
  width: 100%;
  max-height: calc(100% - 32px - 50px);
  padding: 20px 30px;
  overflow-x: hidden;
  overflow-y: auto;
}
.form-btns {
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  left: 0px;
  bottom: 30px;
  bottom: calc(30px + constant(safe-area-inset-bottom));
  bottom: calc(30px + env(safe-area-inset-bottom));
  display: flex;
  justify-content: center;
}
</style>
